import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import writtenNumber from "written-number";
import Vue from "vue";
import { format, parseISO } from "date-fns";
import store from "@/store/index.js";
import { fetchPdfLayoutPorTipo } from "@/api/pdf-layouts/pdf_layouts.js";
import { fetchFatura } from "@/api/faturas/faturas.js";

export default async function generateFatura(
  fatura_id,
  print,
  download,
  unica,
  tipo
) {
  const cancelado_img = () =>
    import(/* webpackChunkName: "cancelado_img" */ "./cancelado_img");

  const empresa = store.state.Usuario.empresa;

  let tipo_layout = "fatura";

  if (tipo == "NOTA_CREDITO") {
    tipo_layout = "notaDeCredito";
  }

  const layout = await fetchPdfLayoutPorTipo(tipo_layout);

  const fatura = await fetchFatura(fatura_id);

  return new Promise((resolve, reject) => {
    let content = [];

    const items_cabecalho = layout.items.filter(
      (item) => item.grupo === "cabecalho" && item.status
    );
    const items_corpo = layout.items.filter(
      (item) => item.grupo === "items" && item.status
    );
    const items_rodape = layout.items.filter(
      (item) => item.grupo === "rodape" && item.status
    );

    let filtro_moeda = "";

    if (fatura.moeda == "G$") {
      filtro_moeda = "guarani";
    } else {
      filtro_moeda = "currency";
    }

    items_cabecalho.forEach((item) => {
      let increase_x = 0;
      let increase_y = 0;

      if (item.posicao == 1) {
        increase_x = layout.obj_imagem1.absolutePosition.x;
        increase_y = layout.obj_imagem1.absolutePosition.y;
      }
      if (item.posicao == 2) {
        increase_x = layout.obj_imagem2.absolutePosition.x;
        increase_y = layout.obj_imagem2.absolutePosition.y;
      }
      if (item.posicao == 3) {
        increase_x = layout.obj_imagem3.absolutePosition.x;
        increase_y = layout.obj_imagem3.absolutePosition.y;
      }

      // LABEL
      if (item.obj_label.status) {
        let label = {};
        label.bold = item.obj_label.bold;
        label.status = item.obj_label.status;
        label.fontSize = parseFloat(item.obj_label.fontSize);

        label.absolutePosition = {
          x:
            parseFloat(item.obj_label.absolutePosition.x) +
            parseFloat(increase_x),
          y:
            parseFloat(item.obj_label.absolutePosition.y) +
            parseFloat(increase_y),
        };

        if (item.obj_label.text.length > item.obj_label.length) {
          label.text =
            item.obj_label.text.substring(0, item.obj_label.length - 3) + "...";
        } else {
          label.text = item.obj_label.text;
        }

        content.push(label);
      }

      // DATA
      if (item.obj_data.status) {
        let data = {};
        data.bold = item.obj_data.bold;
        data.status = item.obj_data.status;
        data.fontSize = parseFloat(item.obj_data.fontSize);
        data.preserveLeadingSpaces = true;

        data.absolutePosition = {
          x:
            parseFloat(item.obj_data.absolutePosition.x) +
            parseFloat(increase_x),
          y:
            parseFloat(item.obj_data.absolutePosition.y) +
            parseFloat(increase_y),
        };

        if (item.obj_data.text === "inicio_timbrado" && !print) {
          data.text = format(parseISO(fatura.inicio_timbrado), "dd/MM/yyyy");
        }

        if (item.obj_data.text === "fim_timbrado" && !print) {
          data.text = format(parseISO(fatura.fim_timbrado), "dd/MM/yyyy");
        }

        if (
          item.obj_data.text === "ponto" ||
          (item.obj_data.text === "ponto" &&
            print &&
            !fatura.timbrado_auto_impressor)
        ) {
          data.text = fatura.ponto
            .toString()
            .padStart(item.obj_data.length, "0");
        }

        if (
          item.obj_data.text === "numero" ||
          (item.obj_data.text === "numero" &&
            print &&
            fatura.timbrado_auto_impressor)
        ) {
          data.text = fatura.numero
            .toString()
            .padStart(item.obj_data.length, "0");
        }

        if (item.obj_data.text === "cliente_nome" && fatura.cliente_nome) {
          if (fatura.cliente_nome.length > item.obj_data.length) {
            data.text =
              fatura.cliente_nome.substring(0, item.obj_data.length - 3) +
              "...";
          } else {
            data.text = fatura.cliente_nome;
          }
        }
        if (item.obj_data.text === "comprovante_venda") {
          data.text = fatura.comprovante_venda;
        }

        if (
          item.obj_data.text === "cliente_endereco" &&
          fatura.cliente_endereco
        ) {
          if (fatura.cliente_endereco.length > item.obj_data.length) {
            data.text =
              fatura.cliente_endereco.substring(0, item.obj_data.length - 3) +
              "...";
          } else {
            data.text = fatura.cliente_endereco;
          }
        }

        if (item.obj_data.text === "cliente_cidade" && fatura.cliente_cidade) {
          if (fatura.cliente_cidade.length > item.obj_data.length) {
            data.text =
              fatura.cliente_cidade.substring(0, item.obj_data.length - 3) +
              "...";
          } else {
            data.text = fatura.cliente_cidade;
          }
        }

        if (item.obj_data.text === "vencimento" && fatura.vencimento) {
          data.text = format(parseISO(fatura.vencimento), "dd/MM/yyyy");
        }

        if (
          item.obj_data.text === "cliente_documento" &&
          fatura.cliente_documento
        ) {
          data.text = fatura.cliente_documento;
        }

        if (
          item.obj_data.text === "cliente_telefone" &&
          fatura.cliente_telefone
        ) {
          data.text = fatura.cliente_telefone;
        }

        if (item.obj_data.text === "condpag" && fatura.condpag) {
          if (fatura.condpag == 1) {
            data.text = "CONTADO";
          }
          if (fatura.condpag == 2) {
            data.text = "CREDITO";
          }
        }

        if (data.text) {
          content.push(data);
        }
      }
    });

    let linhas_pular = [1];
    items_corpo.forEach((item) => {
      fatura.items.forEach((fatura_item, index) => {
        if (item.obj_data.text === "descricao" && fatura_item.descricao) {
          let qtde_linhas = Math.ceil(
            fatura_item.descricao.length / item.obj_data.length
          );
          if (qtde_linhas === 0 || qtde_linhas === 1) {
            linhas_pular.push(1);
          } else {
            linhas_pular.push(qtde_linhas);
          }
        }
      });

      let increase_x = 0;
      let increase_y = 0;

      if (item.posicao == 1) {
        increase_x = layout.obj_imagem1.absolutePosition.x;
        increase_y = layout.obj_imagem1.absolutePosition.y;
      }
      if (item.posicao == 2) {
        increase_x = layout.obj_imagem2.absolutePosition.x;
        increase_y = layout.obj_imagem2.absolutePosition.y;
      }
      if (item.posicao == 3) {
        increase_x = layout.obj_imagem3.absolutePosition.x;
        increase_y = layout.obj_imagem3.absolutePosition.y;
      }

      if (item.obj_data.status) {
        let lineGap = 10;

        fatura.items.forEach((fatura_item, index) => {
          let data = {};
          data.bold = item.obj_data.bold;
          data.status = item.obj_data.status;
          data.fontSize = parseFloat(item.obj_data.fontSize);
          data.preserveLeadingSpaces = true;

          data.absolutePosition = {
            x:
              parseFloat(item.obj_data.absolutePosition.x) +
              parseFloat(increase_x),
            y:
              parseFloat(item.obj_data.absolutePosition.y) +
              parseFloat(increase_y),
          };

          if (index === 0) {
            data.absolutePosition.y =
              parseFloat(item.obj_data.absolutePosition.y) +
              parseFloat(increase_y);
          } else {
            data.absolutePosition.y =
              parseFloat(item.obj_data.absolutePosition.y) +
              parseFloat(increase_y) +
              lineGap;

            lineGap += 10 * (linhas_pular[index + 1] - 0.25);
          }

          const valor = Number(fatura_item.valor ? fatura_item.valor : 0);
          const qtde = Number(fatura_item.qntde ? fatura_item.qntde : 0);
          const valor_10 = Number(
            fatura_item.valor_10 ? fatura_item.valor_10 : 0
          );
          const valor_5 = Number(fatura_item.valor_5 ? fatura_item.valor_5 : 0);
          const valor_isento = Number(
            fatura_item.valor_isento ? fatura_item.valor_isento : 0
          );

          if (item.obj_data.text === "descricao" && fatura_item.descricao) {
            if (fatura_item.descricao.length > item.obj_data.length) {
              data.text = fatura_item.descricao.replace(/(.{38})/g, "$1\n");
            } else {
              data.text = fatura_item.descricao;
            }
          }

          if (item.obj_data.text === "qtde") {
            data.text = qtde;
          }

          if (item.obj_data.text === "valor") {
            data.text = Vue.filter(filtro_moeda)(valor);
            data.text = data.text.padStart(item.obj_data.length);
          }

          if (item.obj_data.text === "valor_isento") {
            data.text = Vue.filter(filtro_moeda)(valor_isento);
            data.text = data.text.padStart(item.obj_data.length);
          }

          if (item.obj_data.text === "valor_iva_5") {
            data.text = Vue.filter(filtro_moeda)(valor_5);
            data.text = data.text.padStart(item.obj_data.length);
          }

          if (item.obj_data.text === "valor_iva_10") {
            data.text = Vue.filter(filtro_moeda)(valor_10);
            data.text = data.text.padStart(item.obj_data.length);
          }

          if (data.text) {
            content.push(data);
          }
        });
      }
    });

    items_rodape.forEach((item) => {
      let increase_x = 0;
      let increase_y = 0;

      if (item.posicao == 1) {
        increase_x = layout.obj_imagem1.absolutePosition.x;
        increase_y = layout.obj_imagem1.absolutePosition.y;
      }
      if (item.posicao == 2) {
        increase_x = layout.obj_imagem2.absolutePosition.x;
        increase_y = layout.obj_imagem2.absolutePosition.y;
      }
      if (item.posicao == 3) {
        increase_x = layout.obj_imagem3.absolutePosition.x;
        increase_y = layout.obj_imagem3.absolutePosition.y;
      }

      if (item.obj_data.status) {
        let data = {};
        data.bold = item.obj_data.bold;
        data.status = item.obj_data.status;
        data.fontSize = parseFloat(item.obj_data.fontSize);
        data.preserveLeadingSpaces = true;

        data.absolutePosition = {
          x:
            parseFloat(item.obj_data.absolutePosition.x) +
            parseFloat(increase_x),
          y:
            parseFloat(item.obj_data.absolutePosition.y) +
            parseFloat(increase_y),
        };

        const valor_total = Number(fatura.valor_total ? fatura.valor_total : 0);
        const desconto = Number(fatura.desconto ? fatura.desconto : 0);
        const valor_total_exento = Number(
          fatura.valor_total_exento ? fatura.valor_total_exento : 0
        );
        const valor_total_gravado5 = Number(
          fatura.valor_total_gravado5 ? fatura.valor_total_gravado5 : 0
        );
        const valor_total_gravado10 = Number(
          fatura.valor_total_gravado10 ? fatura.valor_total_gravado10 : 0
        );
        const iva_5 = Number(fatura.iva_5 ? fatura.iva_5 : 0);
        const iva_10 = Number(fatura.iva_10 ? fatura.iva_10 : 0);
        const total_iva = Number(fatura.total_iva ? fatura.total_iva : 0);

        if (item.obj_data.text === "desconto") {
          data.text = Vue.filter(filtro_moeda)(desconto);
          data.text =
            (tipo == "NOTA_CREDITO" ? "" : "DESCUENTO: ") +
            data.text.padStart(item.obj_data.length);
        }

        if (item.obj_data.text === "sub_total_valor_isento") {
          data.text = Vue.filter(filtro_moeda)(valor_total_exento);
          data.text = data.text.padStart(item.obj_data.length);
        }

        if (item.obj_data.text === "sub_total_valor_iva_5") {
          data.text = Vue.filter(filtro_moeda)(valor_total_gravado5);
          data.text = data.text.padStart(item.obj_data.length);
        }

        if (item.obj_data.text === "sub_total_valor_iva_10") {
          data.text = Vue.filter(filtro_moeda)(valor_total_gravado10);
          data.text = data.text.padStart(item.obj_data.length);
        }

        if (item.obj_data.text === "total_pagar") {
          data.text = Vue.filter(filtro_moeda)(valor_total - desconto);
          data.text = data.text.padStart(item.obj_data.length);
        }

        if (item.obj_data.text === "total_pagar_escrito") {
          data.text = writtenNumber(valor_total - desconto, {
            lang: "es",
          });

          if (fatura.moeda == "G$") {
            data.text += " GUARANIES";
          }
          if (fatura.moeda == "U$") {
            data.text += " DOLARES";
          }

          data.text = data.text.toUpperCase().replace(/(.{50})/g, "$1\n");
          if (
            Number(valor_total - desconto) > 1000000 &&
            Number(valor_total - desconto) < 2000000
          ) {
            data.text = data.text.substring(3);
            data.text = "UN" + data.text;
          }
        }

        if (item.obj_data.text === "iva_5") {
          data.text = Vue.filter(filtro_moeda)(iva_5);
          data.text = data.text.padStart(item.obj_data.length);
        }

        if (item.obj_data.text === "iva_10") {
          data.text = Vue.filter(filtro_moeda)(iva_10);
          data.text = data.text.padStart(item.obj_data.length);
        }

        if (item.obj_data.text === "total_iva") {
          data.text = Vue.filter(filtro_moeda)(total_iva);
          data.text = data.text.padStart(item.obj_data.length);
        }

        if (data.text) {
          content.push(data);
        }
      }
    });

    if (fatura.status == -1) {
      let cancelado_img_source = {};
      cancelado_img_source.absolutePosition = { x: 60, y: 50 };
      cancelado_img_source.opacity = 0.3;
      cancelado_img_source.width = 700;

      cancelado_img().then((module) => {
        cancelado_img_source.image = module.default;
        content.push(cancelado_img_source);
      });
    }

    let docDefinition = {
      info: {
        title: `FATURA_${fatura.ponto}-${fatura.numero
          .toString()
          .padStart(7, "0")}`,
        author: "PH7",
        subject: "subject of document",
        keywords: "keywords for document",
      },
      // pageSize: layout.obj_folha.pageSize,
      pageSize:
        tipo == "NOTA_CREDITO" || tipo == "FATURA"
          ? {
              width: 994.96,
              height: 609.45,
            }
          : layout.obj_folha.pageSize,
      pageMargins: [
        parseFloat(layout.obj_folha.margin_left),
        parseFloat(layout.obj_folha.margin_top),
        parseFloat(layout.obj_folha.margin_right),
        parseFloat(layout.obj_folha.margin_bottom),
      ],
      pageOrientation: layout.obj_folha.pageOrientation,
      content: content,
    };

    // Marca Dagua
    if (layout.obj_marca_dagua.status && !print) {
      let waterMark = {
        bold: true,
        color: "gray",
        italics: false,
        opacity: 0.1,
        text: layout.obj_marca_dagua.text,
      };
      docDefinition.watermark = waterMark;
    }

    if (fatura.timbrado_auto_impressor || !print) {
      let backgroundImages = [];

      // Imagem 1
      if (layout.obj_imagem1.status) {
        const bgImage = {
          image: layout.image,
          width: parseFloat(layout.obj_imagem1.width),
          height: parseFloat(layout.obj_imagem1.height),
          absolutePosition: {
            x: parseFloat(layout.obj_imagem1.absolutePosition.x),
            y: parseFloat(layout.obj_imagem1.absolutePosition.y),
          },
        };

        backgroundImages.push(bgImage);
      }

      // Imagem 2
      if (layout.obj_imagem2.status && !unica) {
        const bgImage = {
          image: layout.image,
          width: parseFloat(layout.obj_imagem2.width),
          height: parseFloat(layout.obj_imagem2.height),
          absolutePosition: {
            x: parseFloat(layout.obj_imagem2.absolutePosition.x),
            y: parseFloat(layout.obj_imagem2.absolutePosition.y),
          },
        };

        backgroundImages.push(bgImage);
      }

      // Imagem 3
      if (layout.obj_imagem3.status && !unica) {
        const bgImage = {
          image: layout.image,
          width: parseFloat(layout.obj_imagem3.width),
          height: parseFloat(layout.obj_imagem3.height),
          absolutePosition: {
            x: parseFloat(layout.obj_imagem3.absolutePosition.x),
            y: parseFloat(layout.obj_imagem3.absolutePosition.y),
          },
        };

        backgroundImages.push(bgImage);
      }

      // adiciona as imagems ao docDefinition
      docDefinition.background = backgroundImages;
    }

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    if (print) {
      pdfDocGenerator.print();
    } else if (download) {
      pdfDocGenerator.download(
        `FATURA_${fatura.ponto}-${fatura.numero
          .toString()
          .padStart(7, "0")}.pdf`
      );
    } else {
      // pdfDocGenerator.open();
      pdfDocGenerator.getDataUrl((data) => {
        resolve(data);
      });
    }
  });
}
